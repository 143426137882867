<template>
  <div class="mainWrapper pb-0">
    <section class="blockElement space flexCol fiveColumn position-relative">
      <div class="container-fluid">
        <div
          class="row g-3"
          v-if="getCurrencyData.elements && Object.keys(getCurrencyData.elements).length"
        >
          <div class="col-12 d-flex align-items-center mb-4">
            <h6 class="mb-0 medium f-22 me-3">{{ $t("marketList.text17") }}</h6>
            <router-link
              to="/rates"
              class="viewAll gray medium ps-1 pe-3 f-14 d-flex align-items-center"
              ><vue-feather class="ms-1" type="chevron-left" size="16"></vue-feather
              >{{ $t("marketList.text18") }}
            </router-link>
          </div>
          <div
            class="col col1"
            v-for="(value, key) in Object.values(getCurrencyData.elements)"
            :key="key"
          >
            <div
              class="card customTradeAdd shadow-none h-100"
              v-if="Object.keys(value).length"
            >
              <div class="card-body px-0">
                <div class="text-center position-relative">
                  <div class="vueRisk">
                    <router-link
                      :to="{
                        name: 'market_details',
                        params: {
                          id: value.id,
                          currency: value.symbol.includes('/')
                            ? value.symbol.split('/').join('')
                            : value.symbol,
                        },
                      }"
                      class="d-flex align-items-center justify-content-center mb-3"
                    >
                      <v-lazy-image
                        width="30"
                        height="30"
                        class="euCurrency"
                        :src="
                          static_vars.marketImageSURL +
                          value.symbol.toUpperCase().replace(/\//g, '') +
                          '.svg'
                        "
                        :alt="value.symbol.toString().split('/')[0].toLowerCase()"
                        :title="value.symbol.toString().split('/')[0].toLowerCase()"
                        @error="handleImgErr($event)"
                        v-if="
                          isImage(value.symbol.toString().split('/')[0].toLowerCase())
                        "
                      />
                    </router-link>
                    <a
                      class="position-absolute star"
                      href="javascript:void(0)"
                      @click="AddWatchList(value)"
                      :class="{ 'disabled': store.customerDetail?.readOnly }"
                      ><vue-feather
                        type="star"
                        size="16"
                        :class="[{ filled: isFollowed(value.id) }]"
                      ></vue-feather
                    ></a>
                  </div>
                  <div class="title">
                    <router-link
                      :to="{
                        name: 'market_details',
                        params: {
                          id: value.id,
                          currency: value.symbol.includes('/')
                            ? value.symbol.split('/').join('')
                            : value.symbol,
                        },
                      }"
                      class="mt-2 mb-3 d-block"
                    >
                      <h6 class="mb-0 medium f-18">{{ value.symbol }}</h6>
                    </router-link>
                    <div class="element px-3">
                      <div
                        class="elementHub d-flex align-items-center justify-content-between otherValue"
                        v-if="value.symbol in store.allPrices"
                      >
                        <p
                          class="f-20 mb-1 bold me-1 w-50"
                          v-if="store.allPrices[value.symbol].buyPrice"
                          :class="[
                            {
                              buy:
                                store.allPrices[value.symbol].buyPrice >
                                store.allPrices[value.symbol].o_b,
                            },
                          ]"
                        >
                          {{
                            parseFloat(store.allPrices[value.symbol].buyPrice).toFixed(3)
                          }}<sub class="f-10">{{
                            getLastTwoDigit(store.allPrices[value.symbol].buyPrice)
                          }}</sub>
                        </p>
                        <p
                          class="f-20 mb-1 bold me-1 w-50"
                          v-if="store.allPrices[value.symbol].sellPrice"
                          :class="[
                            {
                              sell:
                                store.allPrices[value.symbol].sellPrice <
                                store.allPrices[value.symbol].o_s,
                            },
                          ]"
                        >
                          {{
                            parseFloat(store.allPrices[value.symbol].sellPrice).toFixed(
                              3
                            )
                          }}<sub class="f-10">{{
                            getLastTwoDigit(store.allPrices[value.symbol].sellPrice)
                          }}</sub>
                        </p>
                      </div>
                      <div
                        class="elementHub d-flex align-items-center justify-content-center"
                        v-else
                      >
                        <p class="mb-0 bold me-2">{{ value.currentPrice }}</p>
                        <p
                          class="mb-0 px-1 medium d-inline-flex"
                          :class="
                            parseFloat(value.lastChangePercentage) >= 0.0
                              ? 'greenView'
                              : 'redView'
                          "
                        >
                          {{ parseFloat(value.lastChangePercentage) >= 0.0 ? "+" : ""
                          }}{{ parseFloat(value.lastChangePercentage).toFixed(2) || 0 }}%
                        </p>
                      </div>
                      <!-- <div class="progress my-2">
                                                <div class="progress-bar" style="width: 25%;" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div> -->
                      <div
                        class="elementHub d-flex align-items-center"
                        v-if="value.symbol in store.allPrices"
                      >
                        <p class="mb-0 px-1 w-50 green midgray medium f-12">
                          {{ $t("home.text78") }}
                        </p>
                        <p class="mb-0 px-1 w-50 red midgray medium f-12">
                          {{ $t("home.text79") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-3 mb-4" v-else>
          <div class="col-12 mb-4">
            <div class="mt-5 text-center">
              <i
                ><v-lazy-image
                  src="/assets/images/watchlist/empty-star.png"
                  alt="watchlist"
              /></i>
              <h6 class="mb-0 mt-2 f-20 medium fst-normal">
                {{ $t("marketList.text19") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blockElement space pt-0" v-if="!getCurrencyData.lastIncluded">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-5 col-sm-3 col-lg-2">
            <Spinner class="buttonSpiner" v-if="store.singleLoading"></Spinner>
            <a
              class="button fillBtn zulu_btn large me-3 px-md-4"
              href="javascript:void(0);"
              @click.prevent="() => loadMore()"
              v-else
              >{{ $t("marketList.text14") }}</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
  <loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
import { myStore } from "@/store/pinia-store";
// import PriceFeed from "@/store/stomp";
export default {
  setup() {
    const store = myStore();
    return { store };
  },
  data() {
    return {
      errImage: [],
      type: "",
      page: 1,
      showLoginPopup: false,
    };
  },
  watch: {
    "store.userSelectedAccount"() {
      if (
        this.store.user.access_token &&
        Object.keys(this.store.userSelectedAccount).length
      ) {
        this.getCurrency();
      }
    },
    "store.allTypeAccount"() {
      if (this.store.user.access_token && this.store.allTypeAccount) {
        this.getCurrency();
      }
    },
  },
  methods: {
    callWatchList() {
      this.store.getWatchList({}, false, "1");
    },
    AddWatchList(item) {
      if (!this.store.user.access_token) {
        this.showLoginPopup = true;
      } else {
        this.showLoginPopup = false;
        if (this.isFollowed(item.id)) {
          this.addWatchList(item, "DELETE");
        } else {
          this.addWatchList(item, "ADD");
        }
      }
    },
    isFollowed(id) {
      if (this.store.watchList.length) {
        let data = this.store.watchList.map((i) => i.id);
        if (data.includes(parseInt(id))) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    addWatchList(item, type) {
      let json = {};
      if (type == "ADD") {
        json["id"] = item.id;
        json["type"] = "MARKET";
        json["currentPrice"] = item.currentPrice;
        json["dailyPriceChangePercentage"] = item.dailyPriceChangePercentage;
        json["description"] = item.description;
        json["name"] = item.name;
        json["chart"] = {};
        let data = this.store.watchList;
        data.push(json);
        this.store.$patch({ watchList: data });
      } else if (type == "DELETE") {
        let data = this.store.watchList.filter(
          (i) => parseInt(i.id) != parseInt(item.id)
        );
        this.store.$patch({ watchList: data });
      }
      let payload = {
        item: item.id,
        type: "MARKET",
      };
      this.store.addToWatchList(payload, false, this, type);
    },
    loadMore() {
      this.page += 1;
      this.getCurrency();
    },
    handleImgErr(val) {
      var fullSrc = val.target.src.split(".");
      var src = fullSrc[fullSrc.length - 2].split("/");
      var img = src[src.length - 1];
      if (!this.errImage.includes(img)) {
        this.errImage.push(img);
      }
    },
    isImage(img) {
      return this.errImage.includes(img) ? false : true;
    },
    getLastTwoDigit(price) {
      if (price) {
        price = price.toString();
        if (price.split(".")[1] && price.split(".")[1].length < 5) {
          return "";
        } else if (price.split(".")[1] && price.split(".")[1].length >= 5) {
          return price
            .split(".")[1]
            .slice(price.split(".")[1].length - 2, price.split(".")[1].length);
        }
      }
    },
    getCurrency() {
      let formData = {
        from: "",
        to: "",
        limit: 30,
        page: this.page,
      };
      if (this.type == "forex") {
        let forexPayload = {
          categories: [1],
          ...formData,
        };
        this.store.getAllCurrencyCategories(forexPayload, true);
      }
      if (this.type == "stocks") {
        let stocksPayload = {
          categories: [8],
          ...formData,
        };
        this.store.getAllCurrencyCategories(stocksPayload, true);
      }
      if (this.type == "indices") {
        let indicesPayload = {
          categories: [7],
          ...formData,
        };
        this.store.getAllCurrencyCategories(indicesPayload, true);
      }
      if (this.type == "crypto") {
        let cryptoPayload = {
          categories: [4],
          ...formData,
        };
        this.store.getAllCurrencyCategories(cryptoPayload, true);
      }
      if (this.type == "commoditiy") {
        let commoditiesPayload = {
          categories: [3],
          ...formData,
        };
        this.store.getAllCurrencyCategories(commoditiesPayload, true);
      }
      if (this.type == "winners") {
        let form = {
          limit: 30,
          page: this.page,
          sortingStrategy: "TOP_WINNERS",
        };
        this.store.callStrategyCurrencies(form, true);
      }
      if (this.type == "loosers") {
        let form = {
          limit: 30,
          page: this.page,
          sortingStrategy: "TOP_LOSERS",
        };
        this.store.callStrategyCurrencies(form, true);
      }
      if (this.type == "popular") {
        let form = {
          limit: 30,
          page: this.page,
          sortingStrategy: "MOST_POPULAR",
        };
        this.store.callStrategyCurrencies(form, true);
      }
    },
  },
  computed: {
    getCurrencyData() {
      let storedata = {};
      if (this.type == "forex") {
        storedata = this.store.forexList;
      } else if (this.type == "stocks") {
        storedata = this.store.stocksList;
      } else if (this.type == "indices") {
        storedata = this.store.indicesList;
      } else if (this.type == "crypto") {
        storedata = this.store.cryptoList;
      } else if (this.type == "commoditiy") {
        storedata = this.store.commoditiesList;
      } else if (this.type == "winners") {
        storedata = this.store.topWinnersList;
      } else if (this.type == "loosers") {
        storedata = this.store.topLoosersList;
      } else if (this.type == "popular") {
        storedata = this.store.mostPolularCurrencyList;
      }
      if (Object.keys(storedata).length) {
        return storedata;
      } else {
        return {};
      }
    },
  },
  created() {
    if (this.$route.params.currency) {
      this.type = this.$route.params.currency;
      this.getCurrency();
    }
    document.title = `Trade the Markets with ZuluTrade`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        `Trade forex, CFDs, and more with ZuluTrade. Our platform offers real-time trading signals from top traders and advanced tools to help you succeed in the markets.`
      );
    // if (this.store.user.access_token) {
    //     PriceFeed.getInstance(`wss://www.zulutrade.com:443/api/ws/zulutradewsclient/websocket?access_token=${this.store.user.access_token}`, `/topic/feed/group/1000`).activate();
    // } else {
    //     PriceFeed.getInstance(`wss://www.zulutrade.com:443/api/ws/zulutradewsclient/websocket`, `/topic/feed/group/1000`).activate();
    // }
  },
};
</script>

